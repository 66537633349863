<template>
  <c-box class="px-2" style="position: relative; width: 100%" bg="vc-white.20">
    <c-flex
      class="header"
      :align="['start','start', 'space-between']"
      :flexDirection="['column', 'column', 'row']"
      justify="space-between"
      mt="4"
      mb="4"
      :class="{'show-header-back': !hideHeaderBackground, 'hide-header-back': hideHeaderBackground}"
    >
      <c-flex
        pt="10px"
        w="100%"
        :justify="['space-between','start', 'space-between']"
        align="center"
      >
        <c-heading
          mt="4"
          fontSize="2xl"
          fontWeight="700"
          
          :ml="isMobile ? '1' : '3'"
          :mb="['2', '2', '0']"
        >
          Events
        </c-heading>
        <HeaderOptions v-if="isMobile" />
      </c-flex>
      <!--      <c-flex style="margin-right: -280px; margin-top: 50px" justify="end">-->
      <!--        <c-icon-button-->
      <!--          :disabled="eventsOffset === 0"-->
      <!--          @click="moveBack"-->
      <!--          ariaLabel="next button"-->
      <!--          icon="chevron-left"-->
      <!--        />-->
      <!--        <c-icon-button-->
      <!--          :disabled="eventsOffset >= eventsTotal - eventsLimit"-->
      <!--          @click="moveForward"-->
      <!--          ariaLabel="next button"-->
      <!--          icon="chevron-right"-->
      <!--        />-->
      <!--      </c-flex>-->
      <c-flex w="100%" style="align-items: center; justify: end; height: 50px;" :justify="['start', 'end', 'end']">
        <search @hide-sidebar="handleHideSidebar" />
        <HeaderOptions v-if="!isMobile" />
      </c-flex>
    </c-flex>
    <c-flex :flexDirection="['column', 'column', 'row']">
      <c-flex h="100%" :flex="hideSidebar ? '1' : '3'">
        <div style="width: 100%">
          <c-flex
            w="800px"
            h="500px"
            v-if="isFetchingEvents"
            justify="center"
            align="center"
          >
            <c-spinner size="xl" color="orange.500" thickness="2px"></c-spinner>
          </c-flex>
          <div v-else>
            <div
              class="event_div"
              :class="{
                past_event: isPastEvent(event.end_date),
              }"
              v-for="event in events"
              :key="event.id"
            >
              <div
                class="event"
                style="position: relative"
                @click="
                  $router.push({
                    name: 'EventDetails',
                    params: { id: event.id },
                  })
                "
              >
                <c-flex
                  style="position: absolute; right: -2px; top: -20px"
                  v-if="isPastEvent(event.end_date)"
                  align="center"
                  mt="2"
                >
                  <c-icon size="12px" name="info" />
                  <c-text ml="2" fontSize="11px">Event ended</c-text>
                </c-flex>
                <c-image
                  :h="['100px','120px','140px']"
                  :w="['180px','200px','220px']"
                  :src="
                    event.thumb_nail ? event.thumb_nail :
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRFo4PR9dPR7LmV1CqeEB6F5Z3jikCg8xBBA&usqp=CAU'
                  "
                  alt="event thumbnail"
                />

                <div class="event_info">
                  <h2 class="event_title">{{ event.name }}</h2>
                  <small class="date">
                    {{ event.start_date }}, {{ event.start_time
                    }}{{ event.time_zone }}
                  </small>
                  <c-flex>
                    <c-flex justify="start" align="center">
                      <c-icon
                        v-if="event.type === 'VIRTUAL'"
                        size="14px"
                        name="video"
                      />
                      <c-icon v-else size="14px" name="users" />
                      <c-text ml="2" fontSize="14px" fontWeight="600">{{
                        event.type === 'VIRTUAL' ? 'Online' : 'In person'
                      }}</c-text>
                    </c-flex>
                    {{ event.company }}
                  </c-flex>
                  <p class="descr">
                    {{ event.description }}
                  </p>
                  <c-flex justify="start" align="center">
                    <c-icon name="user-friends" />
                    <p class="company ml-3">
                      {{ event.attendees_aggregate.aggregate.count }}
                      {{
                        event.attendees_aggregate.aggregate.count === 1
                          ? 'Attendee'
                          : 'Attendees'
                      }}
                    </p>
                  </c-flex>

                  <!--                <c-button size="sm" variant-color="vc-orange">-->
                  <!--                  Attend Event-->
                  <!--                </c-button>-->
                </div>
              </div>
              <!--            <div class="options">-->
              <!--              <div><i class="far fa-heart"></i> <span>like</span></div>-->
              <!--              <div>-->
              <!--                <i class="far fa-comment-dots"></i> <span>Comment</span>-->
              <!--              </div>-->
              <!--              <div><i class="fas fa-share"></i> <span>Share</span></div>-->
              <!--            </div>-->
              <!--            <div class="options">-->
              <!--              <div><i class="far fa-heart"></i> <span>like</span></div>-->
              <!--              <div>-->
              <!--                <i class="far fa-comment-dots"></i> <span>Comment</span>-->
              <!--              </div>-->
              <!--              <div><i class="fas fa-share"></i> <span>Share</span></div>-->
              <!--            </div>-->
            </div>
          </div>
          <infinite-loading @infinite="getMoreEvents">
            <span slot="no-more">
              <div style="color: rgba(255, 69, 0, 0.45)">No more events</div>
            </span>
            <span slot="no-results">
              <div style="color: rgba(255, 69, 0, 0.45)">No events yet</div>
            </span>
          </infinite-loading>
        </div>
      </c-flex>
      <c-flex
        v-if="!hideSidebar && !isMobile"
        ml="5"
        :flex="hideSidebar ? '0' : isBigMobile ? '2' : '3'"
      >
        <sidebar />
      </c-flex>
    </c-flex>
     <!-- Bottom Drawer Sidebar (Mobile Only) -->
     <c-box
      v-if="!hideSidebar && isMobile"
      class="drawer"
      :style="{ height: drawerHeight + 'px' }"
      @mousedown="startDrag"
      @touchstart="startDrag"
    >
      <!-- Drawer Handle -->
      <c-box
        class="drawer-handle"
        @click="toggleDrawerHeight"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M18 15l-6-6-6 6" />
        </svg>
      </c-box>

      <!-- Sidebar Content -->
       <c-flex mt="55px" justify="center">
        <sidebar />
       </c-flex>
    </c-box>
  </c-box>
</template>

<script>
import { mapState } from 'vuex';
import HeaderOptions from '@/views/App/components/HeaderOptions.vue';
import Search from '@/views/App/Explore/Search.vue';
import Sidebar from '@/views/App/Explore/Sidebar.vue';
import { getEvents } from '@/services/event';
import cloneDeep from 'lodash.clonedeep';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment';
import { EventBus } from "@/eventBus";

export default {
  name: 'Layout',
  components: {
    Search,
    HeaderOptions,
    Sidebar,
    InfiniteLoading,
  },
  data() {
    return {
      hideSidebar: false,
      hideHeaderBackground: false,
      drawerHeight: 200, // Initial height of the drawer
      isDragging: false,
      startY: 0,
      startHeight: 0,
      isFetchingEvents: false,
      events: [],
      eventsLimit: 6,
      eventsOffset: 0,
      eventsTotal: 0,
      event_start: 'asc',
      event_end: 'asc',
      isMobile: false, // Track if the screen is mobile
      isBigMobile: false
    };
  },
  computed: {
    ...mapState('messaging', {
      unreadMessageCount: (state) => state.unreadMessageCount,
    }),
  },
  methods: {
    toggleDrawerHeight() {
      // Toggle between half-screen and full-screen
      this.drawerHeight = this.drawerHeight === 200 ? window.innerHeight * 0.8 : 200;
    },
    startDrag(event) {
      this.isDragging = true;
      this.startY = event.clientY || event.touches[0].clientY;
      this.startHeight = this.drawerHeight;

      // Add event listeners for dragging
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('touchmove', this.onDrag);
      window.addEventListener('mouseup', this.stopDrag);
      window.addEventListener('touchend', this.stopDrag);
    },
    onDrag(event) {
      if (!this.isDragging) return;

      const clientY = event.clientY || event.touches[0].clientY;
      const deltaY = this.startY - clientY;
      this.drawerHeight = Math.min(
        Math.max(this.startHeight + deltaY, 100), // Minimum height
        window.innerHeight * 0.8 // Maximum height (80% of screen)
      );
    },
    stopDrag() {
      this.isDragging = false;

      // Remove event listeners
      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('touchmove', this.onDrag);
      window.removeEventListener('mouseup', this.stopDrag);
      window.removeEventListener('touchend', this.stopDrag);
    },
    checkIfMobile() {
      // Check if the screen width is less than 768px (mobile)
      this.isMobile = window.innerWidth < 768;
      this.isBigMobile = window.innerWidth < 1025;
    },
    isPastEvent(endDate) {
      console.log('ingineeee', endDate);
      return moment(endDate).isBefore(moment());
    },
    moveForward() {
      if (this.eventsOffset < this.eventsTotal) {
        this.eventsOffset += 6;
        this.getEvents();
      } else {
        this.getEvents();
      }
    },
    moveBack() {
      if (this.eventsOffset > 0) {
        this.eventsOffset -= 6;
        this.getEvents();
      } else {
        this.getEvents();
      }
    },
    moveToSavedPage() {
      this.$router.push('/saved');
    },
    handleHideSidebar(status) {
      this.hideSidebar = status;
    },
    getEvents() {
      this.isFetchingEvents = true;
      getEvents({
        limit: this.eventsLimit,
        offset: this.eventsOffset,
      }).then((res) => {
        this.events = res.data.event;
        this.eventsTotal = res.data.event_aggregate.aggregate.count;
        this.isFetchingEvents = false;
      });
    },

    getMoreEvents($state) {
      this.eventsOffset = this.eventsOffset + this.eventsLimit;
      getEvents({
        limit: this.eventsLimit,
        offset: this.eventsOffset,
      }).then((res) => {
        this.events = [...cloneDeep(this.events), ...res.data.event];
        $state.loaded();
        if (this.eventsTotal === this.events.length) {
          $state.complete();
        }
      });
    },
  },

  created() {
    this.getEvents();
    this.checkIfMobile(); // Initial check
    window.addEventListener('resize', this.checkIfMobile); // Update on resize

    EventBus.$on('hideHeaderBackground', (payload) => {
      this.hideHeaderBackground = payload.status;
    });
  },
  // watch: {
  //   $route(to) {
  //     // Perform actions here
  //     if (to.path === '/app/explore') {
  //       this.handleHideSidebar(false);
  //     }
  //   },
  // },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile); // Cleanup
    EventBus.$off('hideHeaderBackground');
  },
  watch: {
    $route(to) {
      if (to.path === '/app/explore' || to.path === '/app/explore/') {
        this.handleHideSidebar(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Drawer Styles (Mobile Only) */
.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
  transition: height 0.3s ease;
}

.drawer-handle {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 50;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f9fbfd;
  cursor: pointer;
  border-bottom: 1px solid #eaeaea;

  svg {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f9fbfd;
  padding-bottom: 20px;
  width: 100%;
}

.show-header-back {
  z-index: 88;
}
.hide-header-back {
  z-index: 0;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  // .saved {
  //   position: absolute;
  //   right: 50px;
  // }

  .menu {
    position: absolute;
    right: 10px;
    margin-top: 8px;
  }

  .notification {
    position: absolute;
    right: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .saved {
    right: 0;
  }

  .menu {
    display: none;
  }

  .header {
    position: sticky;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .menu {
    display: none;
  }
  .saved {
    top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .menu {
    display: none;
  }
}

.event_div {
  padding: 20px;
  border: 1px solid #dae7ec;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 10px;
  height: 180px;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    padding: 10px;
    height: 140px
  }

  .event {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // responsive image
    img {
      border-radius: 10px;
    }

    .event_title {
      max-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* Number of lines to show */
      -webkit-box-orient: vertical;
    }

    
    .event_info {
      margin-left: 20px;
      flex: 1;

      @media only screen and (max-width: 768px) {
        margin-left: 10px
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;

        @media only screen and (max-width: 768px) {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .descr {
        max-height: 45px;
        width: 100%;
        font-size: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;


        @media only screen and (max-width: 768px) {
          max-height: 35px;
          width: 100%;
          font-size: 10px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* Number of lines to show */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis; 
        }
      }

      .company {
        color: #6b7a84;
      }

      .date {
        max-height: 20px;
        color: #6b7a84;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        @media only screen and (max-width: 768px) {}
      }

      .company {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .options {
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    background: rgb(136 162 216 / 14%);
    padding: 10px 20px;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 40px;

      svg {
        margin-right: 10px;
        color: var(--color-secondary);
      }
    }
  }
}

.past_event {
  padding: 20px;
  border: 1px solid #dae7ec;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgb(234 240 253);
  border-radius: 10px;
  height: 180px;
  margin-bottom: 20px;

  .event {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // responsive image
    img {
      border-radius: 10px;
    }

    .event_title {
      max-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* Number of lines to show */
      -webkit-box-orient: vertical;
    }

    .event_info {
      margin-left: 20px;
      flex: 1;

      h2 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .descr {
        max-height: 45px;
        width: 100%;
        font-size: 13px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .company {
        color: #6b7a84;
      }

      .date {
        max-height: 20px;
        color: #6b7a84;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1; /* Number of lines to show */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .company {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .options {
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    background: rgb(136 162 216 / 14%);
    padding: 10px 20px;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 40px;

      svg {
        margin-right: 10px;
        color: var(--color-secondary);
      }
    }
  }
}

.vue__time-picker input.display-time {
  border: 0;
  background-color: #c7d6f466;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Adjust the gap between grid items as needed */
}

.grid-item {
  width: 100%; /* Ensure grid items take up full width of their grid cell */
}

.form-labels {
  font-weight: 500;
  color: #002b49;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: orangered;
}
svg.svgmessage {
  width: 23px;
  height: 23px;
  fill: #6b7a84;
}

.custom_badge {
  position: absolute;
  top: 18px;
  right: 45px;
  background: #f00;
  color: #fff !important;
  border-radius: 50%;
  width: 18px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}
</style>
